<!--  -->
<template>
  <div class="wrapper container">
    <div class="wrapper_main">
      <div class="wrapper_li">
        <div class="title">企业名称</div>
        <div class="text">
          {{ item.companyName || "-" }}
        </div>
      </div>

      <div class="wrapper_li" style="background: #fff">
        <div
          class="title"
          style="
            background: #f6f9fd;
            border-right: 1px solid #f0f0f0;
            min-width: 114px;
            max-width: 114px;
          "
        >
          统一社会信用代码
        </div>
        <div style="border-left: none" class="text">
          {{ item.creditCode || "-" }}
        </div>
      </div>

      <div class="wrapper_li">
        <div class="title">决定书编号</div>
        <div class="text">{{ item.number || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">号牌种类</div>
        <div class="text">{{ item.plateType || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">号牌号码</div>
        <div class="text">{{ item.plateNo || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">处罚结果</div>
        <div class="text">{{ item.punishResult || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">处罚日期</div>
        <div class="text">{{ item.punishDate || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">案件名称</div>
        <div class="text">{{ item.caseName || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">驾驶人姓名</div>
        <div class="text">{{ item.driveName || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">记分</div>
        <div class="text">{{ item.scoring || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">处罚事实</div>
        <div class="text">{{ item.punishFacts || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">处罚类别</div>
        <div class="text">{{ item.punishCategory || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">处罚机构</div>
        <div class="text">{{ item.punishDept || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">处罚行政区划</div>
        <div class="text">{{ item.punishAreaCode || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">法人姓名</div>
        <div class="text">{{ item.legalName || "-" }}</div>
      </div>
      <div class="wrapper_li">
        <div class="title">公示日期</div>
        <div class="text">{{ item.publicityDate || "-" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
//
import { illegalGetDetail } from "@/utils/http.js"; //路径注意
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      id: 0,
      item: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getItemMsg() {
      let id = this.$route.query.id;
      let obj = {
        id,
      };
      const res = await illegalGetDetail(obj);
      this.item = res.data.data.result;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getItemMsg();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden;
}

.wrapper_main {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 10px;
}

.wrapper_li {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-bottom: none;
  background: #f6f9fd;
}

.wrapper_li:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.wrapper_li > .title {
  min-width: 110px;
  max-width: 110px;
  height: 100%;

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #959595;
  box-sizing: border-box;
  padding: 10px;
}

.wrapper_li > .text {
  flex: 1;
  min-height: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  border-left: 1px solid #f0f0f0;
  background: #fff;
}

button {
  padding: 0 !important;
}

.sumPrice {
  display: flex;
  margin-top: 17px;
}

.total {
  margin-left: 111px;
}

.money {
  color: red;
  min-width: 100px;
  text-align: right;
}

.msglist {
  max-height: 250px;
  min-height: 100px;
  overflow: scroll;
  margin-bottom: 15px;
}

.msgitem {
  width: 94%;
  margin-left: 3%;
  align-content: normal;
  min-height: 50px;
  border-radius: 2px;
  border: 1px solid #cccccc;
  margin-top: 10px;
  /* background: rgba(100,100,100,.5); */
}

.msgitems {
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
}

.msgitems > view:nth-of-type(2) {
  margin-top: 10px;
}

.dataContainer {
  max-height: 350px;
  min-height: 100px;
}

.van-search {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  padding-right: 16px !important;
}

.searchButton {
  width: 62px;
  height: 30px;
  background: #e2efff;
  border-radius: 6px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1271ff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 32px; */
}

.wrapper_tips {
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 10px;
  color: #bbbbbb;
}
</style>
